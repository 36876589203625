<template>
  <div class="app-box">
    <div class="list">
      <div class="title-box">
        <div class="line"></div>
        <p class="title">联系人列表</p>
      </div>
      <div class="search">
        <a-input-search placeholder="查找用户" style="width: 240px; text-align: left" @search="onSearch" />
      </div>
      <div class="infinite">
        <div v-infinite-scroll="handleInfiniteOnLoad" class="demo-infinite-container" :infinite-scroll-disabled="busy"
          :infinite-scroll-distance="10">
          <a-list :data-source="data.data">
            <a-list-item slot="renderItem" slot-scope="item" @click="showChat(item)">
              <div>
                <a-avatar shape="square" icon="user" :src="item.avatar" />
                {{ item.name }}
              </div>
              <div class="badge">
                <!-- v-if="item.admin_unread" -->
                <a-badge :count="item.admin_unread" :overflow-count="99" />
              </div>
            </a-list-item>
            <div v-if="loading && !busy" class="demo-loading-container">
              <a-spin />
            </div>
          </a-list>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="no-chat" v-if="!showChatFlag">
        <!-- :style="{
          opacity: !showChatFlag ? 1 : 0,
          height: showChatFlag ? '0px' : '100%',
        }" -->
        暂无聊天记录
      </div>
      <div class="chat" v-else>
        <!--   :style="{
          opacity: showChatFlag ? 1 : 0,
          height: showChatFlag ? '100%' : '0px',
        }" -->
        <div class="chat_title">
          当前与'<span class="chat_name">{{ charTitleData.name }}</span>'的消息记录 微信id:{{ charTitleData.openid }}; 昵称：{{
            charTitleData.nickName
          }};手机号:{{ linkmanData.phone }};订单数:
          <a-tooltip>
            <template #title>点我跳转到订单管理</template>
            <span class="chat_num" @click="goOrderList(charTitleData.name)">{{
              charTitleData.badge
            }}</span>
          </a-tooltip>
        </div>
        <div class="chat_content">
          <div id="position-wrapper" style="width: 100%; height: 100%">
            <div>
              <p class="refresh"><a-spin v-if="isMore" />{{ refreshText }}</p>
              <div class="position-list">
                <!--列表内容-->
                <div>
                  <ul>
                    <li v-for="(item, index) in listData" :key="index" :class="item.is_from == 2 ? 'right' : ''"
                      class="list_content" :id="index + 1 == listData.length ? 'target' : ''">
                      <div class="list_item">
                        <!-- :dir="item.is_from == 2 ? 'rtl' : ''" -->
                        <div>
                          {{
                            item.is_from == 2 ? '康皇科技' : linkmanData.name
                          }}({{ item.create_at_str }})
                        </div>
                        <div class="item_content">{{ item.content }}</div>
                      </div>
                      <!-- <div class="list_item" v-else dir="rtl">
                        <div>
                          {{ linkmanData.name }}({{ item.create_at_str }})
                        </div>
                        <div>{{ item.content }}</div>
                      </div> -->
                    </li>
                  </ul>
                </div>
              </div>
              <!-- <p class="more" v-if="isMore">{{ more }}</p> -->
            </div>
          </div>
        </div>
        <div class="footer">
          <a-textarea placeholder="请输入" :rows="3" allowClear v-model="value" draggable style="resize: none" />
          <div dir="rtl">
            <a-button type="primary" class="submit_btn" @click="submit">
              发送
            </a-button>
          </div>
        </div>
        <!-- {{ charTitleData.name }} -->
      </div>
    </div>
  </div>
</template>

<script>
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
// import reqwest from 'reqwest'
import infiniteScroll from 'vue-infinite-scroll'
import BScroll from 'better-scroll'
import { domain } from '@/siteInfo.js'
export default {
  directives: { infiniteScroll },
  data() {
    return {
      editor: null,
      editorData: '',
      form: {},
      loading: true,
      data: [],
      busy: false,
      showChatFlag: false,
      charTitleData: {},
      timeOutFlag: true,
      refreshText: '查看更多',
      listData: [],
      isMore: false,
      BScroll: null,
      value: '',
      queryData: {
        page: 0,
        limit: 20,
        name: '',
        phone: '',
      },
      listQueryData: {
        page: 1,
        limit: 10,
        customer_id: null,
      },
      linkmanData: {},
      total: null,
      isSubmit: false,
    }
  },
  watch: {
    editorData(val) {
      this.form.content = val
    },
  },
  methods: {
    // 去订单管理页面
    goOrderList(name) {
      this.showChatFlag = false
      this.$router.push({
        path: '/orderList',
        query: {
          name: name,
        },
      })
    },
    // 得到数据
    async getData(callback) {
      try {
        const res = await this.$post(
          domain + '/admin/v1/business/commentContactList',
          this.queryData
        )
        callback(res.data)
      } catch (e) {
        console.log(e)
      }
    },
    // 提交聊天内容
    async submit() {
      const query = {
        content: this.value,
        customer_id: this.charTitleData.customer_id,
      }
      try {
        const res = await this.$post(
          domain + '/admin/v1/business/comment',
          query
        )
        if (res.code === 0) {
          // 添加上拉加载功能
          this.isSubmit = true
          this.listQueryData.page = 1
          await this.getlist()
          // let el = document.getElementById('target')
          // let top = this.BScroll.wrapperHeight - el.offsetTop
          // this.BScroll.scrollTo(0, top, 500)
          // if (Math.abs(this.BScroll.maxScrollY) > this.BScroll.scrollY) {
          //   this.BScroll.maxScrollY = -this.BScroll.scrollY
          // }
          // // this.BScroll.maxScrollY
          this.BScroll.refresh() //当滚动区域的dom结构有变化时，需要执⾏这个操作
          this.BScroll.scrollTo(0, this.BScroll.maxScrollY, 0)
          this.value = ''
        } else {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        this.$message.error(e.messge)
      }
    },
    // 联系人列表下拉刷新
    handleInfiniteOnLoad() {
      const data = this.data.data || []
      if (this.timeOutFlag) {
        this.loading = true
        this.timeOutFlag = false
        if (data?.length >= this.data.total) {
          this.$message.warning('没有更多联系人啦')
          this.busy = true
          this.loading = false
          return
        }
        this.queryData.page += 1
        this.getData((res) => {
          this.data.data = data.concat(res.data)
          this.loading = false
        })
        let timer = setTimeout(() => {
          clearTimeout(timer)
          this.timeOutFlag = true
        }, 500)
      }
    },
    // 搜索联系人
    onSearch(value) {
      this.queryData.name = value
      this.queryData.page = 1
      this.data = []
      this.getData((res) => {
        this.loading = false
        this.data = res
      })
    },
    // 显示聊天内容
    async showChat(item) {
      this.showChatFlag = true
      this.charTitleData = item
      this.listQueryData.customer_id = item.customer_id
      await this.getlist() //请求数据
      this.BScroll = new BScroll('#position-wrapper', {
        scrollY: true, //垂直⽅向滚动
        click: true, //默认会阻⽌浏览器的原⽣click事件，如果需要点击，这⾥要设为true
        pullUpLoad: true, //上拉加载更多
        // bounce: false,
        pullDownRefresh: {
          threshold: 50, //触发pullingDown事件的位置
          stop: 0, //下拉回弹后停留的位置
        },
      })
      this.BScroll.scrollTo(0, this.BScroll.maxScrollY, 0)
      //监听下拉刷新
      this.BScroll.on('pullingDown', async () => {
        if (this.listData.length >= this.total) {
          return
        }
        this.listQueryData.page += 1
        this.isMore = true
        await this.getlist() //请求数据
        this.isMore = false
        this.BScroll.finishPullDown() //每次下拉结束后，需要执⾏这个操作
        this.BScroll.refresh() //当滚动区域的dom结构有变化时，需要执⾏这个操作
      })
      // let el = document.getElementById('target')
      // let top = this.BScroll.scrollerHeight - el.offsetTop
      // console.log(this.BScroll)
      // this.BScroll.scrollTo(0, top, 500)
      //监测实时滚动
      this.BScroll.on('scroll', (position) => {
        if (position.y > 50) {
          this.refreshText = '松⼿开始加载'
        } else {
          if (this.listData.length >= this.total) {
            this.refreshText = '没有更多数据了'
          } else {
            this.refreshText = '查看更多'
          }
        }
      })
    },
    // 拿到聊天数据
    async getlist() {
      try {
        const res = await this.$post(
          domain + '/admin/v1/business/commentList',
          this.listQueryData
        )
        this.linkmanData = res.data.customer_info
        if (res.data.list) {
          if (this.isSubmit) {
            const lastData = Object.values(res.data.list)[
              Object.values(res.data.list).length - 1
            ]
            this.listData = [...this.listData, lastData]
            this.isSubmit = false
          } else {
            this.listData = [...Object.values(res.data.list), ...this.listData]
          }
          this.total = res.data.total
        } else {
          this.total.this.listData.length
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
  created() {
    // this.queryData.page = 1
    // this.getData((res) => {
    //   this.loading = false
    //   this.data = res
    // })
  },
  mounted() { },
}
</script>

<style lang="less" scoped>
.title-box {
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e3ea;
}

.app-box {
  width: 100%;
  height: calc(100vh - 106px);
  display: flex;
}

.list {
  width: 260px;
  height: 100%;
  padding-top: 10px;
  margin-right: 24px;
  background: linear-gradient(226deg, #ffffff 0%, #fcfcfc 100%);
  box-shadow: 0px 12px 31px 0px rgba(187, 192, 196, 0.49);

  .search {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;
  }

  .infinite {
    height: calc(100% - 104px);
  }
}

.badge {
  cursor: pointer;
}

.content {
  flex: 1;
  background: linear-gradient(226deg, #ffffff 0%, #fcfcfc 100%);
  box-shadow: 0px 12px 31px 0px rgba(187, 192, 196, 0.49);

  .no-chat {
    width: 100%;
    height: 100%;
    font-size: 26px;
    color: #cccccc;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .chat {
    padding: 10px 27px;

    .chat_title {
      padding: 10px 0;
      font-size: 14px;
      font-weight: 400;
      color: #212834;

      .chat_name {
        font-weight: bold;
      }

      .chat_num {
        color: rgba(43, 106, 224, 1);
        cursor: pointer;
      }
    }

    .chat_content {
      height: 603px;
      background: #f6f7fa;
      overflow: hidden;
      padding: 0 24px;

      .list_content {
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;
      }

      .refresh {
        text-align: center;
      }

      .item_content {
        display: inline-block;
        padding: 10px 10px;
        background: rgba(43, 106, 224, 0.1);
        border-radius: 4px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: #212834;
        max-width: 80%;
      }

      .right {
        justify-content: flex-end;

        .list_item {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          flex-direction: column;
        }

        .item_content {
          background: #e7eaf0;
        }
      }
    }
  }

  .footer {
    margin-top: 16px;
    border: 1px solid #979797;

    /deep/ .ant-input {
      border: 0px;
      outline: 0px;
      resize: none;
    }

    /deep/ .ant-input:focus {
      box-shadow: none;
    }

    .submit_btn {
      margin: 10px;
    }
  }
}

.demo-infinite-container {
  border-top: 1px solid #e8e8e8;
  // border-radius: 4px;
  overflow: auto;
  padding: 8px 10px;
  height: 100%;
}

.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
</style>
<style lang="less">
// .form-edit {
//   .upload-card {
//     .ant-upload.ant-upload-select-picture-card {
//       margin-right: 0;
//       margin-bottom: 0;
//     }
//   }
// }
//
</style>
